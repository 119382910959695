<template>
  <div>
    <div class="mx-auto px-6 sm:px-6 py-4">
      <div class="py-4">
        <div>
          <h2 class="text-2xl font-semibold leading-tight">Machines</h2>
        </div>
        <ModalErrorConnection v-if="error"/>
        <div class="grid grid-cols-2">
          <div class="my-2 flex sm:flex-row flex-col">
            <div class="flex flex-row mb-1 sm:mb-0">
              <div class="relative">
                <select v-model.number="pageSize" @change="changePerPage" class="appearance-none h-full rounded-l border-t lg:rounded-r-none lg:border-r-0 border-r border-b border-l block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="100">200</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              <div class="block relative hidden lg:table-cell">
                <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
                    <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                  </svg>
                </span>
                <input v-model="search" placeholder="Search" class="appearance-none rounded-r rounded-l h-full sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
              </div>
            </div>
          </div>
          <div class="">
            <div class="buttons float-right my-2">
              <button class="save" @click="$router.push({ name: 'MachinesDetailNew',params: {pageno:currentPage,perpage:pageSize,order:currentSort,search:search} })">
                <i class="fa fa-plus"></i>
                Create new machine
              </button>
            </div>
          </div>
        </div>
        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div class="inline-block min-w-full lg:shadow">
            <table class="min-w-full leading-normal tables w-full hidden lg:table">
              <thead>
                <tr>
                  <th class="text-left hidden lg:table-cell" @click="sort('device_id')">Device Id <i class="fas fa-chevron-down" v-if="(currentSort=='device_id' && currentSortDir=='asc')"></i><i class="fas fa-chevron-up" v-if="(currentSort=='device_id' && currentSortDir=='desc')"></i> <i class="fas fa-chevron-down" v-if="(currentSort!='device_id')"></i></th>
                  <th class="text-left hidden lg:table-cell" @click="sort('device_sn')">Device S/N <i class="fas fa-chevron-down" v-if="(currentSort=='device_sn' && currentSortDir=='asc')"></i><i class="fas fa-chevron-up" v-if="(currentSort=='device_sn' && currentSortDir=='desc')"></i> <i class="fas fa-chevron-down" v-if="(currentSort!='device_sn')"></i></th>
                  <th class="text-left hidden lg:table-cell" @click="sort('name')">Name <i class="fas fa-chevron-down" v-if="(currentSort=='name' && currentSortDir=='asc')"></i><i class="fas fa-chevron-up" v-if="(currentSort=='name' && currentSortDir=='desc')"></i> <i class="fas fa-chevron-down" v-if="(currentSort!='name')"></i></th>
                  <th class="text-left hidden lg:table-cell" @click="sort('machine_sn')">S/N <i class="fas fa-chevron-down" v-if="(currentSort=='machine_sn' && currentSortDir=='asc')"></i><i class="fas fa-chevron-up" v-if="(currentSort=='machine_sn' && currentSortDir=='desc')"></i> <i class="fas fa-chevron-down" v-if="(currentSort!='machine_sn')"></i></th>
                  <th class="text-left hidden lg:table-cell">Group Owner</th>
                  <th class="text-left hidden lg:table-cell" @click="sort('company_name')">Company Name <i class="fas fa-chevron-down" v-if="(currentSort=='company_name' && currentSortDir=='asc')"></i><i class="fas fa-chevron-up" v-if="(currentSort=='company_name' && currentSortDir=='desc')"></i> <i class="fas fa-chevron-down" v-if="(currentSort!='company_name')"></i></th>
                  <th class="text-left hidden lg:table-cell" @click="sort('machine_type')">Machine Type <i class="fas fa-chevron-down" v-if="(currentSort=='machine_type' && currentSortDir=='asc')"></i><i class="fas fa-chevron-up" v-if="(currentSort=='machine_type' && currentSortDir=='desc')"></i> <i class="fas fa-chevron-down" v-if="(currentSort!='machine_type')"></i></th>
                  <th class="text-left hidden lg:table-cell" @click="sort('online')">Online <i class="fas fa-chevron-down" v-if="(currentSort=='online' && currentSortDir=='asc')"></i><i class="fas fa-chevron-up" v-if="(currentSort=='online' && currentSortDir=='desc')"></i> <i class="fas fa-chevron-down" v-if="(currentSort!='online')"></i></th>
                  <th class="hidden lg:table-cell">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in paginateItem" :key="item.id" class="flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap"  @mouseleave="hidePop"><!--@mouseover="showPop"-->
                  <td class="border-b border-gray-200 bg-white text-sm w-full lg:w-auto lg:table-cell relative lg:static text-center lg:text-left">{{item.device_id}}</td>
                  <td class="border-b border-gray-200 bg-white text-sm w-full lg:w-auto lg:table-cell relative lg:static text-center lg:text-left">{{item.device_sn}}</td>
                  <td class="border-b border-gray-200 bg-white text-sm w-full lg:w-auto lg:table-cell relative lg:static text-center lg:text-left">{{item.name}}</td>
                  <td class="border-b border-gray-200 bg-white text-sm w-full lg:w-auto lg:table-cell relative lg:static text-center lg:text-left">{{item.machine_sn}}</td>
                  <td class="border-b border-gray-200 bg-white text-sm w-full lg:w-auto lg:table-cell relative lg:static text-center lg:text-left" v-html="findOwner(item.proprietari)"></td>
                  <td class="hidden lg:block border-b border-gray-200 bg-white text-sm w-full lg:w-auto lg:table-cell relative lg:static text-center lg:text-left">{{item.company_name}}</td>
                  <td class="hidden lg:block border-b border-gray-200 bg-white text-sm w-full lg:w-auto lg:table-cell relative lg:static text-center lg:text-left">{{item.machine_type}}</td>
                  <td class="hidden lg:block border-b border-gray-200 bg-white text-sm w-full lg:w-auto lg:table-cell relative lg:static text-center lg:text-left"><span class="text-green-600" v-show="item.online==true"><i class="fas fa-power-off"></i></span><span class="text-red-600" v-show="item.online==false"><i class="fas fa-power-off"></i></span></td>
                  <td class="hidden lg:block border-b border-gray-200 bg-white text-sm w-full lg:w-auto lg:table-cell relative lg:static text-center lg:text-left">
                    <span class="lg:hidden absolute top-7 left-0 px-1 text-xs font-bold uppercase">Actions</span>
                    <div class="relative">
                      <div class="flex items-center justify-center p-12">
                        <div class="relative inline-block text-left dropdown2">
                          <span class="rounded-md shadow-sm relative z-0">
                            <button class="save senza" type="button">
                              <i class="fa fa-ellipsis-v"></i>
                            </button>
                            </span>
                          <div class="dropdown2-menu relative opacity-0 invisible"><!-- -->
                            <div class="absolute z-50 right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
                              <div class="py-1">
                                <a href="javascript:void(0)" tabindex="1" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" @click="$router.push({ name: 'MachinesDetail', params: { id: index, dati: item,pageno:currentPage,perpage:pageSize,order:currentSort,search:search} })"><div><i class="fa fa-edit"></i> Edit</div></a>
                                <a href="javascript:void(0)" tabindex="0" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" ><div><i class="fa fa-users"></i> Users List</div></a>
                                <a tabindex="2" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left" role="menuitem" target="_blank" :href="loginJson.user.base_url+'/'+item.vpnlink" v-show="item.vpnlink2_online==true"><div><i class="fa fa-share-alt"></i> VPN Link Online</div></a>
                                <a href="javascript:void(0)" tabindex="2" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left" role="menuitem" @click="deleteItem"><div><i class="fa fa-trash"></i> Delete</div></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>              
                    </div>
                    <!--<div class="flex items-center justify-center">
                      <div class="m-3">
                        <button class="save" @click="$router.push({ name: 'UsersDetail', params: { id: item.id, dati: item} })">
                          <i class="fa fa-pencil"></i>
                          Edit
                        </button>
                      </div>
                      <div class="m-3" @click="deleteItem">
                       <button class="save">
                            <i class="fa fa-trash"></i>
                            Delete
                          </button> 
                      </div>
                    </div>-->
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="block lg:hidden">
              <div class="my-1 px-4 py-4 mb-8 rounded border border-gray-200" v-for="item in sortedComp" :key="item.id">
                <div class="flex justify-between items-center">
                  <span class="font-light text-gray-600">ID: {{item.device_id}}</span>
                  <span class="px-2 py-1 bg-gray-400 text-gray-100 font-bold rounded hover:bg-gray-500">{{item.company_name}}</span>
                </div>
                <div class="mt-2">
                  <span class="text-2xl text-gray-700 font-bold hover:text-gray-600">{{item.name}}</span>
                  <p class="mt-2 text-gray-600">
                    <b>Device S/N:</b> {{item.device_sn}}<br>
                    <b>S/N:</b> {{item.machine_sn}}<br>
                    <b>Online</b><span class="text-green-600" v-show="item.online==true"><i class="fas fa-power-off"></i></span><span class="text-red-600" v-show="item.online==false"><i class="fas fa-power-off"></i></span>
                  </p>
                </div>
                <div class="mt-2" style="display:none;" :ref="`div-${item.id}`" :id="`div-${item.id}`">
                  <p class="text-gray-600">
                    <b>Machine Type</b> {{item.machine_type}}<br>
                    <b>Group Owner</b> <span v-html="findOwner(item.proprietari)"></span>
                  </p>
                  <div class="mt-10 mb-10 text-right">
                    <div class="relative">
                      <div class="flex items-center justify-center p-12">
                        <div class="relative inline-block text-left dropdown2">
                          <span class="rounded-md shadow-sm relative z-0">
                            <button class="save" type="button">
                              <i class="fa fa-ellipsis-v"></i>
                            </button>
                            </span>
                          <div class="dropdown2-menu opacity-0 invisible relative">
                            <div class="absolute z-50 right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
                              <div class="py-1">
                                <a href="javascript:void(0)" tabindex="1" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" @click="$router.push({ name: 'MachinesDetail', params: { id: index, dati: item,pageno:currentPage,perpage:pageSize,order:currentSort,search:search} })"><div><i class="fa fa-edit"></i> Edit</div></a>
                                <a href="javascript:void(0)" tabindex="0" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" ><div><i class="fa fa-users"></i> Users List</div></a>
                                <a tabindex="2" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left" role="menuitem" target="_blank" :href="loginJson.user.base_url+'/'+item.vpnlink" v-show="item.vpnlink2_online==true"><div><i class="fa fa-share-alt"></i> VPN Link Online</div></a>
                                <a href="javascript:void(0)" tabindex="2" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left" role="menuitem" @click="deleteItem"><div><i class="fa fa-trash"></i> Delete</div></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>              
                    </div>
                  </div>
                </div>
                <div class="flex justify-between items-center mt-4">
                  <span class="px-2 py-1 bg-gray-600 text-gray-100 font-bold rounded hover:bg-gray-500" @click="showHide(item.id)"><i class="fa fa-plus text-sm" :ref="`btn-${item.id}`"></i> Detail</span>
                </div>
              </div>
            </div>
            <div class="absolute w-auto mt-2 bg-white border border-gray-200 shadow-lg px-2 py-4" :style="{ top: coordY+'px',left: coordX+'px' }" v-show="isPop">
              <div class="grid grid-cols-2 px-6">
                <span class="font-semibold px-2 text-left">Username</span>
                <span class="text-right">Admin</span>
                <span class="font-semibold px-2 text-left">Display Name</span>
                <span class="text-right">Admin</span>
                <span class="font-semibold px-2 text-left">Email</span>
                <span class="text-right">admin@nomail.no</span>
                <span class="font-semibold px-2 text-left"><i class="fa fa-bolt"></i></span>
                <span class="text-right">10.63.20.4</span>
                <span class="font-semibold px-2 text-left">Company</span>
                <span class="text-right">TECHNICAL</span>
              </div>
            </div>
            <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
              <span class="text-xs xs:text-sm text-gray-900">Showing {{currentPage}} of {{totalPage}} pages</span> 
              <paginate
                :first-last-button="true"
                :page-count="totalPage"
                :margin-pages="2"
                :page-range="3"
                :click-handler="currentPageCheck"
                :force-page="currentPage"
                :prev-text="htmlPrev"
                :next-text="htmlNext"
                :page-class="'w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in rounded-full'"
                :active-class="'text-gray-900 font-semibold'"
                :prev-class="'h-12 w-12 ml-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer pagination-button'"
                :next-class="'h-12 w-12 ml-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer pagination-button'"
                :container-class="'inline-flex mt-2 xs:mt-0'">
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="modalDelete" :resizable="false" :adaptive="true" :width="'400px'" :height="'250px'" :styles="'background:none;box-shadow:none;'">
      <div class="h-full flex justify-center items-center">
        <div class="bg-white rounded-lg">
          <div class="w-96 border-t-8 border-red-600 rounded-lg flex">
            <div class="w-1/3 pt-6 flex justify-center"> 
              <i class="fa fa-alert"></i>
            </div>
            <div class="w-full pt-9 pr-4">
              <h3 class="font-bold text-red-700">Delete machine?</h3>
              <p class="py-4 text-sm text-gray-400">Are you sure you want to delete the selected machine?</p>
            </div>
          </div>
          <div class="p-4 flex space-x-4">
            <a href="#" class="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm" @click="closeModal">Cancel</a>
            <a href="#" class="w-1/2 px-4 py-3 text-center text-pink-100 bg-red-600 rounded-lg hover:bg-pink-700 hover:text-white font-bold text-sm" @click="deleteModal">Delete Machine</a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import { AUTH_URL } from "actions/auth";
import ModalErrorConnection from '@/components/ModalErrorConnection.vue'
import Paginate from 'vuejs-paginate'

export default {
  name: 'groups',
  data: () => ({
    cells:false,
    items: [],
    serials:[],
    pageSize: 10,
    currentPage:3,
    currentSort:'device_id',
    currentSortDir:'asc',
    totalPage : 0,
    error: false,
    nameModal: '',
    hover: false,
    search: '',
    coordX:0,
    coordY:0,
    isPop: false,
    time:null,
    search: '',
    users: [],
    htmlNext: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right w-6 h-6"><polyline points="9 18 15 12 9 6"></polyline></svg>',
    htmlPrev: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left w-6 h-6"><polyline points="15 18 9 12 15 6"></polyline></svg>'
  }),
  components:{
    ModalErrorConnection,
    Paginate
  },
  computed:{
    ...mapGetters(["getProfile", "isAuthenticated", "isProfileLoaded","loginJson","isRefresh","authStatus"]),
    /*sortedComp:function() {
      return this.items.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((search, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      }).filter((search) => {
        if(this.search!=''){
          //this.items.username
        }
        console.log(search)
        return true;
      })
    }*/
    paginateItem:function(){
      var items = this.sortedComp;
      //console.log(items.length)
      this.totalPage = Math.ceil(items.length/this.pageSize);
      return items.filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      })
    },
    sortedComp:function() {
      return this.items.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(this.search!=''){
          this.currentPage = 1;
          if(
            row.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.company_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.company_uic.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.details.address.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.details.cap.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.details.city.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.details.notes.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.details.state.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.device_id.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.device_sn.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.machine_sn.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            row.machine_type.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            //row.proprietari.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
            (row.vpnlink != null && row.vpnlink.toLowerCase().indexOf(this.search.toLowerCase()) > -1) || 
            row.vpnlink2.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          ){
            return true;
          }
        }else{
          return true;
        }
      })
    }
  },
  methods: {
    showHide(id){
      if(this.$refs['div-'+id][0].style.display=='none'){
        this.$refs['div-'+id][0].style.display = 'block';
        this.$refs['btn-'+id][0].className = 'fa fa-minus text-sm'
      }else{
        this.$refs['div-'+id][0].style.display = 'none';
        this.$refs['btn-'+id][0].className = 'fa fa-plus text-sm'
      }
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    showPop(event){
      this.time = setTimeout(() => this.isPop = true, 5000)
      this.coordX = event.pageX;
      this.coordY = event.pageY;
    },
    hidePop(){
      if (this.time) {
        clearTimeout(this.time);
      }
      this.isPop = false;
    },
    deleteModal: function(){
      this.$modal.hide('modalDelete')
    },
    closeModal: function(){
      this.$modal.hide('modalDelete')
    },
    deleteItem: function(){
      this.$modal.show('modalDelete')
    },
    changePerPage: function(){
      this.currentPage = 1;
      this.totalPage = Math.ceil(this.items.length/this.pageSize);
    },
    getItems : function(){
      axios({url: AUTH_URL+'master/api/v2/users', method: 'GET' , 
        headers: { 
          'Authorization': 'Bearer '+localStorage.getItem("user-token"),
          'Accept' : '*/*',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
          }
        })
        .then(resp => {
          //console.log('caricati gli utenti')
          this.users = resp.data
          axios({url: AUTH_URL+'master/api/v2/machines', method: 'GET' , 
            headers: { 
              'Authorization': 'Bearer '+localStorage.getItem("user-token"),
              'Accept' : '*/*',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
              }
            })
            .then(resp => {
              //console.log('ok caricate le macchine')
              this.items = resp.data.InfoDeviceArray
              for(var i=0; i<this.items.length;i++){
                  this.items[i].proprietari = [];
                  for(var u=0; u<this.users.length;u++){
                      if(this.items[i].owners.includes(this.users[u].id)){
                          this.items[i].proprietari.push(this.users[u].group_id)
                      }
                  }
              }
              //console.log(this.items)
              this.totalPage = Math.ceil(this.items.length/this.pageSize);            
            })
            .catch(err => {
              this.error = true;
              console.error(err)
          })
        })
        .catch(err => {
          this.error = true;
          console.error(err)
      })



    },
    async getSerials(element){
        return this.serials.find((items) => items.device_id === element.device_id)
    },
    findOwner: function(id){
      return id.toString().replace(/,/g, '<br>');
    },
    sort:function(s) {
      this.currentPage = 1;
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },
    currentPageCheck(pageno){
      this.currentPage = pageno;
    },
    getParams: function(){
      
    },
    getUsers(){
      axios({url: AUTH_URL+'master/api/v2/users', method: 'GET' , 
        headers: { 
          'Authorization': 'Bearer '+localStorage.getItem("user-token"),
          'Accept' : '*/*',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
          }
        })
        .then(resp => {
          //console.log('caricati gli utenti')
          console.log(resp.data)
          this.users = resp.data
        })
        .catch(err => {
          this.error = true;
          console.error(err)
      })
    }
  },
  mounted(){
    
  },
  beforeMount: function beforeMount () {
    this.getItems()
    this.currentPage = this.$route.params.pageno || 1
    this.pageSize = this.$route.params.perpage || 10
    this.currentSort = this.$route.params.order || 'device_id'
    this.search = this.$route.params.search || ''
  }
}
</script>
<style>
.dropdown2:focus-within .dropdown2-menu {
  opacity:1;
  /*transform: translate(0) scale(1);*/
  visibility: visible;
}
table tr td div.info{display: none;background-color: white;position:absolute;z-index: 1000;top:0;}
table tr:hover td div.info{background: red;display: block;}
</style>